@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/*
Default Style
============================*/
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

body {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #666666;
  background-color: #f9f9f9;
}
body.white-body {
  background-color: #ffffff;
}

a {
  display: inline-block;
  transition: all ease 0.5s;
  text-decoration: none;
  color: #666666;
}
a:hover {
  text-decoration: none;
  color: #ef8017;
}
a:focus {
  text-decoration: none;
}

button {
  outline: 0 !important;
  box-shadow: none;
  border: none;
}
button:focus {
  box-shadow: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222222;
  font-weight: 700;
}

h3 {
  font-size: 22px;
  line-height: 1.4;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

p {
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  margin: 0;
}

.form-control {
  height: 40px;
  color: #1396be;
  border: 1px solid #616161;
  background-color: transparent;
  border-radius: 4px;
  font-size: 15px;
  padding: 10px 15px;
  width: 100%;
}
.form-control::placeholder {
  color: #676a6a;
}
.form-control:focus {
  color: #000000;
  background-color: transparent;
  box-shadow: unset;
  outline: 0;
  border: 1px solid #1396be;
}

.form-control:hover:focus,
.form-control:focus {
  box-shadow: unset;
}

textarea.form-control {
  height: auto;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-minus-100 {
  margin-top: -100px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

/*
Bg-color Style*/
.bg-color {
  background-color: #f9f9f9;
}

.fdfcf8-bg-color {
  background-color: #fdfcf8;
}

/*
Default Btn Area Style*/
.default-btn {
  font-size: 16px;
  color: #ef8017;
  padding: 10px 10px;
  transition: all 0.4s ease-out 0s;
  text-align: center;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #ef8017;
  position: relative;
  z-index: 1;
}
.default-btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  background-color: #ef8017;
  z-index: -1;
  transition: all 0.4s ease-out 0s;
}
.default-btn:hover {
  color: #ffffff;
  border-color: #ef8017;
  background-color: #ef8017;
}
.default-btn:hover::before {
  width: 50%;
  left: 0;
  right: auto;
}

.default-btn1 {
  border: 1px solid #313131;
  color: #313131;
  border-radius: 4px;
  box-shadow: 0px 0px 8px black;
}
.default-btn1::before {
  background: linear-gradient(to right, #12d495 0%, #33c8c1 50%, #2a9c92 100%);
  border-radius: 20px;
}
.default-btn1:hover {
  color: #ffffff;
  border-color: #1396be;
  background: linear-gradient(to right, #12d495 0%, #33c8c1 50%, #2a9c92 100%);
  border-radius: 20px;
}

.viewbtn {
  border: 1px solid #079ca3;
  width: 100px;
  background-color: transparent;
  color: #079ca3;
  cursor: pointer;
  border-radius: 5px;
}

.viewbtn:hover {
  background: #079ca3;
  border: 1px solid #079ca3;
  color: #fff;
  transition: all 0.4s ease;
}

.viewbtn:before {
  background: #079ca3;
}

.deletebtn {
  border: 1px solid #d4780e;
  color: #d4780e;
}

.deletebtn:hover {
  background: #d4780e;
  border: 1px solid #d4780e;
}

.deletebtn:before {
  background: #d4780e;
}

/*
Read More Btn Area Style*/
.read-more {
  font-weight: 600;
  font-size: 15px;
  color: #222222;
}
.read-more:hover {
  color: #ef8017;
}

/*
Section Title Area Style*/
.section-title {
  max-width: 750px;
  margin: -5px auto 30px;
  text-align: left;
  position: relative;
}
.section-title span {
  font-size: 16px;
  display: block;
  margin-bottom: 15px;
  color: #ef8017;
  font-weight: 500;
}
.section-title h2 {
  font-size: 40px;
  margin-bottom: 5px;
  position: relative;
}
.section-title h2:last-child {
  margin-bottom: 0;
}
.section-title.text-align-left {
  text-align: left;
  margin-left: 0;
}
.section-title.white-title span {
  color: #ef8017;
}
.section-title.white-title h2 {
  color: #ffffff;
}
.section-title.white-title p {
  color: #ffffff;
}

.section-btn {
  margin-bottom: 50px;
  position: absolute;
  right: 15px;
  bottom: 0;
}

/*
Header Area Style
======================================================*/
.top-header {
  padding: 4px 0;
}
.top-header .header-left-content {
  line-height: 1;
}
.top-header .header-left-content li {
  display: inline-block;
  margin-right: 15px;
}
.top-header .header-left-content li:last-child {
  margin-right: 0;
}
.top-header .header-left-content li a i {
  color: #999999;
  display: inline-block;
  font-size: 17px;
  transition: all ease 0.5s;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  border: 1px solid #5f5f5f;
  text-align: center;
}
.top-header .header-left-content li a:hover i {
  color: #1396be;
  background-color: #1396be;
  border-color: #1396be;
}
.top-header .header-right-content {
  float: right;
  line-height: 1;
}
.top-header .header-right-content li {
  display: inline-block;
  color: #999999;
  font-size: 14px;
  position: relative;
  padding-left: 17px;
}
.top-header .header-right-content li::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 1px;
  height: 100%;
  background-color: #868383;
}
.top-header .header-right-content li:first-child::after {
  position: relative;
}
.top-header .header-right-content li:last-child {
  margin-right: 0;
}
.top-header .header-right-content li span {
  display: block;
  font-weight: 400;
  font-size: 18px;
  color: #666666;
  margin-top: 10px;
}
.top-header .header-right-content li a {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.top-header .header-right-content li i {
  color: #ef8017;
  display: inline-block;
  margin-right: 10px;
  font-size: 17px;
  top: 0px;
  left: 0;
}
.top-header .header-right-content li a:hover {
  color: #1396be !important;
}
.top-header.top-header-two {
  background-color: #fff;
}
.top-header.top-header-two .header-left-content li a i {
  color: #908c8c;
}
.top-header.top-header-two .header-left-content li a:hover i {
  background-color: #ffffff;
  color: #1396be;
  border-color: #1396be;
}
.top-header.top-header-two .header-right-content li {
  color: #f9f9f9;
}
.top-header.top-header-two .header-right-content li span {
  color: #ffffff;
}
.top-header.top-header-two .header-right-content li a {
  color: #424141;
}
.top-header.top-header-two .header-right-content li i {
  color: #424141;
}
.top-header.top-header-two .header-right-content li p {
  color: #212529;
}
.top-header .profile-overlay.profile-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}
.top-header.top-header-three {
  overflow: hidden;
  background-color: #eaecf1;
}
.top-header.top-header-three .appointment {
  padding-left: 0;
  top: -12px;
  z-index: 1;
}
.top-header.top-header-three .appointment::after {
  display: none;
}
.top-header.top-header-three .appointment::before {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 75px;
  background-color: #ef8017;
  z-index: -1;
}
.top-header.top-header-three .appointment a {
  margin-top: 0;
  padding: 0 20px;
  color: #ffffff;
}
.top-header.top-header-three .appointment a:hover {
  color: #ffffff;
}
.top-header.top-header-three .appointment a i {
  position: relative;
  color: #ffffff;
}

/*
Nav Area Style
======================================================*/
.navbar-area .main-nav {
  background: linear-gradient(to right, #12d495 0%, #33c8c1 50%, #2a9c92 100%);
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-area .main-nav .navbar {
  padding: 0;
}
.navbar-area .main-nav .navbar .navbar-brand {
  font-size: 0;
  padding: 0;
}
.navbar-area .main-nav nav .navbar-nav .nav-item {
  position: relative;
  padding: 0 0;
}
.navbar-area .main-nav nav .navbar-nav .nav-item a {
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
  transition: all ease 0.5s;
  margin-left: 0;
  margin-right: 0;
  padding: 30px 0;
  position: relative;
  z-index: 1;
  margin: 0 14px;
}
.navbar-area .main-nav nav .navbar-nav .nav-item a i {
  font-size: 22px;
  line-height: 0;
  position: relative;
  top: 4px;
}
.navbar-area .main-nav nav .navbar-nav .nav-item a:hover {
  color: #313131;
}
.navbar-area .main-nav nav .navbar-nav .nav-item a.active {
  color: #313131;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
  position: absolute;
  top: 130%;
  left: 15px;
  opacity: 0;
  width: 250px;
  visibility: hidden;
  z-index: 99;
  display: block;
  padding: 0;
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-top: 2px solid #ef8017;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li {
  position: relative;
  padding: 0;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  font-size: 14px;
  color: #222222;
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px dashed #eeeeee;
  margin-left: 0;
  margin-right: 0;
  text-transform: capitalize;
  display: block;
  font-weight: 600;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a i {
  float: right;
  top: 12px;
  transition: all ease 0.5s;
  font-size: 20px;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a::before {
  display: none;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover {
  color: #ef8017;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #ef8017;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 100%;
  top: 0;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li:last-child a {
  border-bottom: none;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  position: absolute;
  left: -98%;
  top: 20px !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  position: absolute;
  right: -100%;
  top: 0;
  opacity: 0 !important;
  visibility: hidden !important;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #222222;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover {
  color: #ef8017;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus {
  color: #ef8017;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #ef8017;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1 !important;
  visibility: visible !important;
  top: -4px !important;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu li:hover .dropdown-menu {
  opacity: 1 !important;
  visibility: visible !important;
  top: -20px !important;
}
.navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu:last-child .dropdown-menu {
  left: auto;
  right: -100%;
}
.navbar-area .main-nav nav .navbar-nav .nav-item:hover ul {
  opacity: 1;
  visibility: visible;
  top: 92%;
}
.navbar-area .others-option .option-item {
  color: #666666;
  display: inline-block;
  line-height: 1;
  position: relative;
  top: 0;
}
.navbar-area .others-option .option-item .search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}
.navbar-area .others-option .option-item .search-overlay.search-popup .search-form {
  position: relative;
}
.navbar-area .others-option .option-item .search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #222222;
  outline: 0;
  transition: all ease 0.5s;
  font-size: 15px;
  padding-top: 4px;
  padding-left: 15px;
}
.navbar-area .others-option .option-item .search-overlay.search-popup .search-form .search-input:focus {
  border-color: #ef8017;
}
.navbar-area .others-option .option-item .search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: #ef8017;
  border: none;
  width: 50px;
  outline: 0;
  color: #ffffff;
  transition: all ease 0.5s;
  padding: 0;
}
.navbar-area .others-option .option-item .search-overlay.search-popup .search-form .search-button:hover {
  background-color: #222222;
}
.navbar-area .others-option .option-item .profile-overlay.profile-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}
.navbar-area .others-option .option-item .profile-overlay.profile-popup .search-form {
  position: relative;
}
.navbar-area .others-option .option-item .profile-overlay.profile-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #222222;
  outline: 0;
  transition: all ease 0.5s;
  font-size: 15px;
  padding-top: 4px;
  padding-left: 15px;
}
.navbar-area .others-option .option-item .profile-overlay.profile-popup .search-form .search-input:focus {
  border-color: #ef8017;
}
.navbar-area .others-option .option-item .profile-overlay.profile-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: #ef8017;
  border: none;
  width: 50px;
  outline: 0;
  color: #ffffff;
  transition: all ease 0.5s;
  padding: 0;
}
.navbar-area .others-option .option-item .profile-overlay.profile-popup .search-form .search-button:hover {
  background-color: #222222;
}
.navbar-area .others-option .option-item .search-btn {
  cursor: pointer;
  transition: all ease 0.5s;
  color: #222222;
  font-size: 24px;
  line-height: 1;
}
.navbar-area .others-option .option-item .search-btn:hover {
  color: #ef8017;
}
.navbar-area .others-option .option-item .login {
  cursor: pointer;
  transition: all ease 0.5s;
  color: #222222;
  font-size: 24px;
  line-height: 1;
}
.navbar-area .others-option .option-item .login:hover {
  color: #ef8017;
}
.navbar-area .others-option .option-item .close-btn {
  cursor: pointer;
  display: none;
  transition: all ease 0.5s;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  color: #222222;
}
.navbar-area .others-option .option-item .close-btn:hover {
  color: #ef8017;
}
.navbar-area .others-option .option-item .close-btn.active {
  display: block;
}
.navbar-area .others-option .cart-icon {
  display: inline-block;
  margin-left: 12px;
  position: relative;
  top: 4px;
  margin-right: 10px;
}
.navbar-area .others-option .cart-icon a i {
  font-size: 30px;
  transition: all ease 0.5s;
  line-height: 1;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  color: #ffffff;
  border-radius: 50px;
}
.navbar-area .others-option .cart-icon a span {
  position: absolute;
  top: 5px;
  right: -10px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  background-color: #ffffff;
  border-radius: 50%;
  text-align: center;
  color: #313131;
  font-size: 11px;
}
.navbar-area .others-option .cart-icon a:hover i {
  color: #313131;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.leftoffset {
  margin-left: 8.33333333%;
}

/*
Mobile Nav Area Style
======================================================*/
.mobile-nav {
  display: none;
}

/*
Others Option For Responsive Area Style
======================================================*/
.others-option-for-responsive {
  display: none;
}
.others-option-for-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 9991;
  position: absolute;
  right: 60px;
  top: 20px;
}
.others-option-for-responsive .dot-menu .inner {
  display: flex;
  align-items: center;
  height: 30px;
}
.others-option-for-responsive .dot-menu .inner .circle {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 2px;
  transition: all ease 0.5s;
  background-color: #000000;
}
.others-option-for-responsive .dot-menu:hover .inner .circle {
  background-color: #ef8017;
}
.others-option-for-responsive .container {
  position: relative;
}
.others-option-for-responsive .container .container {
  position: absolute;
  right: 0;
  top: 10px;
  max-width: 262px;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  transition: all ease 0.5s;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}
.others-option-for-responsive .container .container.active {
  opacity: 1;
  visibility: visible;
}
.others-option-for-responsive .option-inner {
  padding: 15px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}
.others-option-for-responsive .option-inner .others-option {
  background-color: #ffffff;
  border-radius: 30px;
  padding: 10px;
  position: absolute;
  top: 70px;
  right: 0;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.others-option-for-responsive .option-inner .others-option .option-item {
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
}
.others-option-for-responsive .option-inner .others-option .option-item:first-child {
  margin-left: 0;
  padding-left: 0;
}
.others-option-for-responsive .sidebar-menu {
  display: inline-block;
  margin-left: 10px;
}
.others-option-for-responsive .sidebar-menu a i {
  font-size: 25px;
  background-color: #ef8017;
  height: 50px;
  width: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  border-radius: 50px;
}

.carousel-control-prev-icon {
  margin: 0 !important;
  margin-right: 5px !important;
  position: absolute;
  left: -77px;
  top: 81%;
}
.carousel-control-prev-icon i {
  background-color: #ffffff;
  font-size: 35px;
  border: 1px solid #c6cfe1;
  color: #c6cfe1;
  padding: 5px 8px;
  transition: all ease 0.5s;
}

.carousel-control-next-icon {
  margin: 0 !important;
  margin-left: 5px !important;
  position: revert;
  right: -77px;
  top: 81%;
}
.carousel-control-next-icon i {
  background-color: #ffffff;
  font-size: 35px;
  border: 1px solid #c6cfe1;
  color: #c6cfe1;
  padding: 5px 8px;
  transition: all ease 0.5s;
}

/***partner**/
.partner-area {
  position: relative;
}
.partner-area .partner-bg {
  background-color: #f8f8f8;
  padding: 50px;
}
.partner-area .partner-bg .partner-item {
  line-height: 1;
  text-align: center;
}
.partner-area .partner-bg.partner-bg-two {
  background-color: #eaecf1;
  border-radius: 4px;
}
.partner-area .owl-theme .owl-nav {
  margin-top: 0;
  padding: 0;
  background: none;
}
.partner-area .owl-theme .owl-nav .owl-prev {
  margin: 0 !important;
  margin-right: 5px !important;
  position: absolute;
  left: -25px;
  top: 91%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area .owl-theme .owl-nav .owl-prev i {
  font-size: 40px;
  color: #079aa1;
  transition: all ease 0.5s;
}
.partner-area .owl-theme .owl-nav .owl-next {
  margin: 0 !important;
  margin-left: 5px !important;
  position: absolute;
  right: -25px;
  top: 91%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area .owl-theme .owl-nav .owl-next i {
  font-size: 40px;
  color: #079aa1;
  transition: all ease 0.5s;
}
.partner-area .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.partner-area .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #ffffff;
  background-color: #079aa1;
  border-color: #079aa1;
  border-radius: 50%;
}
.partner-area .partner-shape {
  position: absolute;
  top: -200px;
  right: 0;
}

.partner-area1 {
  position: relative;
}
.partner-area1 .partner-bg {
  padding: 20px 20px 0px 20px;
}
.partner-area1 .partner-bg .partner-item {
  line-height: 1;
  text-align: center;
}
.partner-area1 .partner-bg.partner-bg-two {
  background-color: #eaecf1;
  border-radius: 4px;
}
.partner-area1 .owl-theme .owl-nav {
  margin-top: 0;
  padding: 0;
  background: none;
}
.partner-area1 .owl-theme .owl-nav .owl-prev {
  margin: 0 !important;
  margin-right: 5px !important;
  position: absolute;
  left: -25px;
  top: 74%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area1 .owl-theme .owl-nav .owl-prev i {
  font-size: 40px;
  color: #079aa1;
  transition: all ease 0.5s;
}
.partner-area1 .owl-theme .owl-nav .owl-next {
  margin: 0 !important;
  margin-left: 5px !important;
  position: absolute;
  right: -25px;
  top: 74%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area1 .owl-theme .owl-nav .owl-next i {
  font-size: 40px;
  color: #079aa1;
  transition: all ease 0.5s;
}
.partner-area1 .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.partner-area1 .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #ffffff;
  background-color: #079aa1;
  border-color: #079aa1;
  border-radius: 50%;
}
.partner-area1 .partner-shape {
  position: absolute;
  top: -200px;
  right: 0;
}

.partner-area4 {
  position: relative;
}
.partner-area4 .partner-bg {
  padding: 50px 50px 5px 50px;
}
.partner-area4 .partner-bg .partner-item {
  line-height: 1;
  text-align: center;
}
.partner-area4 .partner-bg.partner-bg-two {
  background-color: #eaecf1;
  border-radius: 4px;
}
.partner-area4 .owl-theme .owl-nav {
  margin-top: 0;
  padding: 0;
  background: none;
}
.partner-area4 .owl-theme .owl-nav .owl-prev {
  margin: 0 !important;
  margin-right: 5px !important;
  position: absolute;
  left: -25px;
  top: 100%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
  border-radius: 50%;
}
.partner-area4 .owl-theme .owl-nav .owl-prev i {
  font-size: 40px;
  color: #079aa1;
  transition: all ease 0.5s;
}
.partner-area4 .owl-theme .owl-nav .owl-next {
  margin: 0 !important;
  margin-left: 5px !important;
  position: absolute;
  right: -25px;
  top: 100%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
  border-radius: 50%;
}
.partner-area4 .owl-theme .owl-nav .owl-next i {
  font-size: 40px;
  color: #079aa1;
  transition: all ease 0.5s;
}
.partner-area4 .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.partner-area4 .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #ffffff;
  background-color: #079aa1;
  border-color: #079aa1;
  border-radius: 50%;
}
.partner-area4 .partner-shape {
  position: absolute;
  top: -200px;
  right: 0;
}

.partner-area2 {
  position: relative;
}
.partner-area2 .partner-bg {
  padding: 20px 20px 0px 20px;
}
.partner-area2 .partner-bg .partner-item {
  line-height: 1;
  text-align: center;
}
.partner-area2 .partner-bg.partner-bg-two {
  background-color: #eaecf1;
  border-radius: 4px;
}
.partner-area2 .owl-theme .owl-nav {
  margin-top: 0;
  padding: 0;
  background: none;
}
.partner-area2 .owl-theme .owl-nav .owl-prev {
  margin: 0 !important;
  margin-right: 5px !important;
  position: absolute;
  left: -25px;
  top: 74%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area2 .owl-theme .owl-nav .owl-prev i {
  font-size: 40px;
  color: #079aa1;
  transition: all ease 0.5s;
}
.partner-area2 .owl-theme .owl-nav .owl-next {
  margin: 0 !important;
  margin-left: 5px !important;
  position: absolute;
  right: -25px;
  top: 74%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area2 .owl-theme .owl-nav .owl-next i {
  font-size: 40px;
  color: #079aa1;
  transition: all ease 0.5s;
}
.partner-area2 .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.partner-area2 .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #ffffff;
  background-color: #079aa1;
  border-color: #079aa1 r;
  border-radius: 50%;
}
.partner-area2 .partner-shape {
  position: absolute;
  top: -200px;
  right: 0;
}

.partner-area3 {
  position: relative;
}
.partner-area3 .partner-bg {
  background-color: #f8f8f8;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 50px;
}
.partner-area3 .partner-bg .partner-item {
  line-height: 1;
  text-align: center;
}
.partner-area3 .partner-bg.partner-bg-two {
  background-color: #eaecf1;
  border-radius: 4px;
}
.partner-area3 .owl-theme .owl-nav {
  margin-top: 0;
  padding: 0;
  background: none;
}
.partner-area3 .owl-theme .owl-nav .owl-prev {
  margin: 0 !important;
  margin-right: 5px !important;
  position: absolute;
  left: 7px;
  top: 65%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area3 .owl-theme .owl-nav .owl-prev i {
  font-size: 40px;
  border-radius: 50%;
  background-color: #d8d8d800;
  color: #079ca3;
  transition: all ease 0.5s;
}
.partner-area3 .owl-theme .owl-nav .owl-next {
  margin: 0 !important;
  margin-left: 5px !important;
  position: absolute;
  right: 7px;
  top: 65%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area3 .owl-theme .owl-nav .owl-next i {
  font-size: 40px;
  border-radius: 50%;
  background-color: #d8d8d800;
  color: #079ca3;
  transition: all ease 0.5s;
}
.partner-area3 .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.partner-area3 .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #ffffff;
  background-color: #079ca3;
  border-color: #079ca3;
  border-radius: 50%;
}
.partner-area3 .partner-shape {
  position: absolute;
  top: -200px;
  right: 0;
}

.partner-area5 {
  position: relative;
}
.partner-area5 .partner-bg {
  background-color: #f8f8f8;
  padding: 50px;
}
.partner-area5 .partner-bg .partner-item {
  line-height: 1;
  text-align: center;
}
.partner-area5 .partner-bg.partner-bg-two {
  background-color: #eaecf1;
  border-radius: 4px;
}
.partner-area5 .owl-theme .owl-nav {
  margin-top: 0;
  padding: 0;
  background: none;
}
.partner-area5 .owl-theme .owl-nav .owl-prev {
  margin: 0 !important;
  margin-right: 5px !important;
  position: absolute;
  left: 8px;
  top: 125%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area5 .owl-theme .owl-nav .owl-prev i {
  font-size: 40px;
  color: #079ca3;
  transition: all ease 0.5s;
}
.partner-area5 .owl-theme .owl-nav .owl-next {
  margin: 0 !important;
  margin-left: 5px !important;
  position: absolute;
  right: 8px;
  top: 125%;
  transform: translateY(-130px);
  padding: 0px;
  font-size: 0px;
  background: none;
}
.partner-area5 .owl-theme .owl-nav .owl-next i {
  font-size: 40px;
  color: #079ca3;
  transition: all ease 0.5s;
}
.partner-area5 .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.partner-area5 .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #ffffff;
  background-color: #079ca3;
  border-color: #079ca3;
  border-radius: 50%;
}
.partner-area5 .partner-shape {
  position: absolute;
  top: -200px;
  right: 0;
}

.services-area .owl-theme .owl-nav {
  margin-top: 0;
  padding: 0;
}
.services-area .owl-theme .owl-nav .owl-prev {
  margin: 0 !important;
  margin-right: 5px !important;
  position: absolute;
  left: -77px;
  top: 81%;
  transform: translateY(-34px);
}
.services-area .owl-theme .owl-nav .owl-prev i {
  background-color: #ffffff;
  font-size: 35px;
  border: 1px solid #c6cfe1;
  color: #c6cfe1;
  padding: 5px 8px;
  transition: all ease 0.5s;
}
.services-area .owl-theme .owl-nav .owl-next {
  margin: 0 !important;
  margin-left: 5px !important;
  position: absolute;
  right: -77px;
  top: 81%;
  transform: translateY(-34px);
}
.services-area .owl-theme .owl-nav .owl-next i {
  background-color: #ffffff;
  font-size: 35px;
  border: 1px solid #c6cfe1;
  color: #c6cfe1;
  padding: 5px 8px;
  transition: all ease 0.5s;
}
.services-area .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.services-area .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #ffffff;
  background-color: #079ca3;
  border-color: #079ca3;
}

/***/
.appointment-box {
  position: relative;
  top: 110px;
}
.appointment-box p {
  background-color: #ef8017;
  color: #ffffff;
  margin-bottom: 0;
  padding: 20px 30px;
  text-align: center;
}
.appointment-box .appointment-form {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px 30px;
}
.appointment-box .appointment-form .form-group {
  margin-bottom: 20px;
}
.appointment-box .appointment-form .form-group i {
  position: absolute;
  top: 17px;
  right: 20px;
  line-height: 1;
  font-size: 18px;
  color: #ef8017;
  z-index: 99;
}
.appointment-box .appointment-form .form-group .form-control {
  padding: 10px 20px;
  background-color: #ffffff;
}
.appointment-box .appointment-form .form-group .form-control:focus {
  border-color: #ef8017;
}
.appointment-box .appointment-form .default-btn {
  width: 100%;
}

/*
Feature Area Style
======================================================*/
/*
Choose Us Area Style
======================================================*/
.choose-us-area {
  position: relative;
  z-index: 1;
}
.choose-us-area .section-title {
  text-align: left;
  margin-left: 0;
}
.choose-us-area .choose-us-shape {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: -1;
}

.choose-us-content h3 {
  font-size: 25px;
  margin-bottom: 15px;
}
.choose-us-content .default-btn {
  margin-top: 10px;
}

/*
Choose Us Area Two Style
======================================================*/
.choose-us-content-two .top-title {
  font-weight: 500;
  color: #ef8017;
  display: block;
  margin-bottom: 20px;
}
.choose-us-content-two h2 {
  font-size: 39px;
  margin-bottom: 25px;
}
.choose-us-content-two p {
  font-weight: 500;
  margin-bottom: 20px;
}
.choose-us-content-two ul li {
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #222222;
}
.choose-us-content-two ul li::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 15px;
  width: 2px;
  height: 100%;
  background-color: #222222;
}
.choose-us-content-two ul li span {
  position: absolute;
  top: -5px;
  left: 0;
  font-weight: 600;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #d5dae4;
  text-align: center;
  color: #222222;
  transition: all ease 0.5s;
}
.choose-us-content-two ul li:last-child {
  margin-bottom: 0;
}
.choose-us-content-two ul li:last-child::before {
  display: none;
}
.choose-us-content-two ul li:hover span {
  background-color: #ef8017;
  color: #ffffff;
}
.choose-us-content-two .default-btn {
  margin-top: 37px;
}

.choose-us-img-two {
  position: relative;
  z-index: 1;
}
.choose-us-img-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 210px;
  height: 100%;
  background-color: #f9f9f9;
}

.address-and-week {
  position: absolute;
  top: 30px;
  left: 0;
  max-width: 360px;
}
.address-and-week p {
  background-color: #ef8017;
  color: #ffffff;
  padding: 30px;
  margin-bottom: 0;
}
.address-and-week .address-week {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.address-and-week .address-week .address {
  margin-bottom: 25px;
}
.address-and-week .address-week .address li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.address-and-week .address-week .address li:last-child {
  margin-bottom: 0;
}
.address-and-week .address-week .address li span {
  font-weight: 500;
  color: #222222;
}
.address-and-week .address-week .address li i {
  color: #222222;
  line-height: 1;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
}
.address-and-week .address-week .week li {
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: 1px dashed #e3e3e3;
}
.address-and-week .address-week .week li span {
  float: right;
}
.address-and-week .address-week .week li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.appointment-here-form {
  background-color: #afd0bf;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
  padding: 50px;
  padding-bottom: 20px;
  position: relative;
  z-index: 2;
  background-image: url(assets/img/book-now-shape.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}
.appointment-here-form h3 {
  margin-bottom: 28px;
  line-height: 1;
}
.appointment-here-form .form-group {
  position: relative;
  margin-bottom: 28px;
}
.appointment-here-form .form-group i {
  position: absolute;
  top: 17px;
  right: 20px;
  line-height: 1;
  font-size: 18px;
  color: #ef8017;
  z-index: 99;
}
.appointment-here-form .form-group .form-control {
  padding: 10px 20px;
  background-color: #ffffff;
  border-right: none;
}
.appointment-here-form .form-group .form-control:focus {
  border-color: #ffffff;
}
.appointment-here-form .default-btn {
  width: 100%;
  margin-bottom: 28px;
}

.table-condensed {
  border-collapse: collapse;
  width: 258px;
  text-align: center;
}
.table-condensed .prev {
  background-color: #f2f9fc;
  border-radius: 4px;
  transition: all ease 0.5s;
  cursor: pointer;
}
.table-condensed .prev:hover {
  background-color: #ef8017;
}
.table-condensed .next {
  background-color: #f2f9fc;
  border-radius: 4px;
  transition: all ease 0.5s;
  cursor: pointer;
}
.table-condensed .next:hover {
  background-color: #ef8017;
}
.table-condensed .day {
  transition: all ease 0.5s;
  cursor: pointer;
  transition: all ease 0.5s;
  border-radius: 4px;
  font-size: 14px;
}
.table-condensed .day:hover {
  background-color: #ef8017;
  color: #ffffff;
}

.datepicker.datepicker-dropdown.dropdown-menu.datepicker-orient-left.datepicker-orient-bottom {
  border-radius: 0 !important;
}

.services-area {
  position: relative;
  z-index: 1;
}
.services-area .owl-theme .owl-nav {
  margin-top: 0;
  padding: 0;
}
.services-area .owl-theme .owl-nav .owl-prev {
  margin: 0 !important;
  margin-right: 5px !important;
  position: absolute;
  left: -77px;
  top: 81%;
  transform: translateY(-34px);
}
.services-area .owl-theme .owl-nav .owl-prev i {
  background-color: #ffffff;
  font-size: 35px;
  border: 1px solid #c6cfe1;
  color: #c6cfe1;
  padding: 5px 8px;
  transition: all ease 0.5s;
}
.services-area .owl-theme .owl-nav .owl-next {
  margin: 0 !important;
  margin-left: 5px !important;
  position: absolute;
  right: -77px;
  top: 81%;
  transform: translateY(-34px);
}
.services-area .owl-theme .owl-nav .owl-next i {
  background-color: #ffffff;
  font-size: 35px;
  border: 1px solid #c6cfe1;
  color: #c6cfe1;
  padding: 5px 8px;
  transition: all ease 0.5s;
}
.services-area .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.services-area .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #ffffff;
  background-color: #ef8017;
  border-color: #ef8017;
}
.services-area .col-lg-3:nth-child(2) .single-services {
  margin-right: 20px;
}
.services-area .col-lg-3:nth-child(3) .single-services {
  margin-right: 20px;
}
.services-area .col-lg-3:nth-child(4) .single-services {
  margin-right: 20px;
}
.services-area .services-shape {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: -1;
}
.services-area.services-area-two {
  background-image: url(assets/img/banner-1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}
.services-area.services-area-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eaecf1;
  opacity: 0.85;
  z-index: -1;
}
.services-area.services-area-two .single-services {
  padding: 0;
}
.services-area.services-area-two .single-services .services-img {
  padding: 10px;
  padding-bottom: 0;
}
.services-area.services-area-two .single-services .services-content {
  padding: 30px;
  padding-top: 28px;
}
.services-area.services-area-two.bg-t {
  background-image: none;
}
.services-area.services-area-two.bg-t::before {
  display: none;
}
.services-area.services-area-three {
  background-image: none;
  border-top: 1px solid #e5e5e5;
}
.services-area.services-area-three::before {
  display: none;
}

.single-services {
  text-align: center;
  padding: 30px;
  transition: all ease 0.5s;
}
.single-services .services-icon {
  padding: 20px;
  border-radius: 50%;
  width: 150px;
  height: 100px;
  margin: 0 auto 20px;
}
.single-services .services-icon img {
  width: 150px;
  transition: all ease 0.5s;
}
.single-services h3 {
  margin-bottom: 12px;
}
.single-services h3 a {
  color: #222222;
}
.single-services h3 a:hover {
  color: #ef8017;
}
.single-services:hover {
  transform: translateY(-5px);
}
.single-services:hover img {
  transform: rotateY(360deg);
}

/**Teristol**/
.testimonial-area {
  border-bottom: 1px solid #d7d7d7;
}
.testimonial-area .owl-theme .owl-dots {
  margin-top: 0px !important;
  line-height: 1;
  margin-bottom: 30px;
}
.testimonial-area .owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  background: #94a5c6;
  transition: all ease 0.5s;
  margin: 0 10px;
}
.testimonial-area .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #ef8017;
}
.testimonial-area .owl-theme .owl-dots .owl-dot.active span {
  background-color: #079ca3;
}
.testimonial-area .owl-carousel .owl-item img {
  width: auto;
}
.testimonial-area.testimonial-area-two .testimonial-content {
  text-align: center;
  max-width: 870px;
  margin: 0 auto 30px;
}
.testimonial-area.testimonial-area-two .owl-carousel .owl-item img {
  margin: auto;
}

.testimonial-img {
  position: relative;
  text-align: center;
  z-index: 1;
}
.testimonial-img .testimonial-shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.testimonial-content i {
  font-size: 40px;
  color: #ef8017;
  text-align: center;
  display: block;
  line-height: 1;
  margin-bottom: 15px;
}
.testimonial-content p {
  font-size: 20px;
  margin-bottom: 15px;
  color: #222222;
}
.testimonial-content h2 {
  font-size: 23px;
}
.testimonial-content h3 {
  margin-bottom: 10px;
  color: #ef8017;
}
.testimonial-content img {
  width: unset;
}
.testimonial-content .quotes {
  color: #079aa1;
}

/*
Doctors Area Style
======================================================*/
.doctors-area .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  right: 0;
  top: -100px;
}
.doctors-area .owl-theme .owl-nav .owl-prev {
  margin: 0 !important;
  margin-right: 5px !important;
}
.doctors-area .owl-theme .owl-nav .owl-prev i {
  background-color: #ffffff;
  font-size: 35px;
  border: 1px solid #c6cfe1;
  color: #c6cfe1;
  padding: 5px 8px;
  transition: all ease 0.5s;
}
.doctors-area .owl-theme .owl-nav .owl-next {
  margin: 0 !important;
  margin-left: 5px !important;
}
.doctors-area .owl-theme .owl-nav .owl-next i {
  background-color: #ffffff;
  font-size: 35px;
  border: 1px solid #c6cfe1;
  color: #c6cfe1;
  padding: 5px 8px;
  transition: all ease 0.5s;
}
.doctors-area .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.doctors-area .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #ffffff;
  background-color: #ef8017;
  border-color: #ef8017;
}

.single-doctors {
  transition: all ease 0.5s;
  margin-bottom: 30px;
}
.single-doctors .doctors-img {
  position: relative;
  overflow: hidden;
}
.single-doctors .doctors-img ul {
  line-height: 1;
  background-color: rgba(19, 49, 110, 0.9);
  text-align: center;
  padding: 30px;
  margin: 10px;
  position: absolute;
  bottom: -150px;
  left: 0;
  right: 0;
  transition: all ease 0.5s;
}
.single-doctors .doctors-img ul li {
  display: inline-block;
  padding: 0 5px;
}
.single-doctors .doctors-img ul li a i {
  font-size: 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  transition: all ease 0.5s;
}
.single-doctors .doctors-img ul li a:hover i {
  background-color: #ffffff;
  color: #ef8017;
}
.single-doctors .doctors-content {
  background-color: #eaecf1;
  padding: 30px;
}
.single-doctors .doctors-content span {
  display: block;
  margin-bottom: 5px;
}
.single-doctors .doctors-content h3 {
  margin-bottom: 0;
}
.single-doctors.two {
  margin-top: 30px;
}
.single-doctors.four {
  margin-top: 30px;
}
.single-doctors.six {
  margin-top: 30px;
}
.single-doctors:hover {
  transform: translateY(-5px);
}
.single-doctors:hover .doctors-img ul {
  bottom: 0;
}

.partner-bg p, .partner-bg ul li, .packagecard p, .packagecard ul li {
  color: #000;
}

.partner-bg ul li, .packagecard ul li {
  font-size: 14px;
  margin-left: 20px;
}

/*
Counter Area Style
======================================================*/
.counter-bg {
  background-color: #eaecf1;
  padding: 50px;
  padding-bottom: 20px;
  border-radius: 4px;
  background-image: url(assets/img/counter-bg.png);
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.counter-bg.counter-bg-three {
  background-color: transparent;
}

.single-counter {
  position: relative;
  padding-left: 90px;
  margin-bottom: 30px;
  transition: all ease 0.5s;
}
.single-counter i {
  font-size: 70px;
  color: #079ca3;
  line-height: 1;
  position: absolute;
  top: 7px;
  left: 0;
}
.single-counter h2 {
  font-size: 40px;
  color: #ef8017;
  line-height: 1;
}
.single-counter h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: normal;
  color: #666666;
}
.single-counter:hover {
  transform: translateY(-5px);
}

/*
Online Treatment Area Style
======================================================*/
.online-treatment-area {
  background-color: #eaecf1;
  position: relative;
  z-index: 1;
}
.online-treatment-area .online-treatment-shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.online-treatment-area .online-treatment-shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.online-treatment-img {
  position: relative;
  margin-right: 30px;
  margin-bottom: 30px;
  z-index: 1;
}
.online-treatment-img::before {
  content: "";
  position: absolute;
  top: 30px;
  right: -30px;
  width: 100%;
  height: 100%;
  background-color: #c6cfe1;
  z-index: -1;
}

.online-treatment-content {
  margin-left: 30px;
}
.online-treatment-content span {
  font-weight: 500;
  color: #ef8017;
  display: block;
  margin-bottom: 15px;
}
.online-treatment-content h2 {
  font-size: 40px;
  margin-bottom: 25px;
}
.online-treatment-content ul li {
  margin-bottom: 20px;
  position: relative;
  font-weight: 500;
  padding-left: 40px;
}
.online-treatment-content ul li:last-child {
  margin-bottom: 0;
}
.online-treatment-content ul li i {
  position: absolute;
  top: -4px;
  left: 0;
  font-size: 30px;
}

/*
Pricing Area Style
======================================================*/
.single-pricing {
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  transition: all ease 0.5s;
  margin-bottom: 30px;
}
.single-pricing h3 {
  margin-bottom: 15px;
  margin-top: -5px;
}
.single-pricing h2 {
  font-size: 40px;
  margin-bottom: 0;
}
.single-pricing h2 sub {
  font-size: 25px;
  position: relative;
  top: -3px;
  font-weight: normal;
}
.single-pricing span {
  margin-bottom: 15px;
  display: block;
}
.single-pricing h4 {
  font-size: 16px;
  margin-bottom: 20px;
  text-decoration: underline;
}
.single-pricing ul {
  margin-bottom: 20px;
}
.single-pricing ul li {
  margin-bottom: 10px;
}
.single-pricing ul li:last-child {
  margin-bottom: 0;
}
.single-pricing.active {
  background-color: #eaecf1;
}
.single-pricing:hover {
  transform: translateY(-5px);
  background-color: #eaecf1;
}

/*
portfolio Area Style
======================================================*/
.our-portfolio-area {
  background-image: linear-gradient(#f2f3f5, transparent);
}

.single-portfolio {
  transition: all ease 0.5s;
  position: relative;
  margin-bottom: 30px;
}
.single-portfolio .portfolio-content {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  margin: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.single-portfolio .portfolio-content h3 {
  margin-bottom: 0;
  font-size: 20px;
}
.single-portfolio .portfolio-content h3 a {
  color: #222222;
}
.single-portfolio .portfolio-content h3 a:hover {
  color: #ef8017;
}
.single-portfolio:hover {
  transform: translateY(-5px);
}

.shorting .mix {
  display: none;
}

.shorting-menu {
  text-align: center;
  margin-bottom: 40px;
}
.shorting-menu button.filter {
  background-color: transparent;
  font-weight: 500;
  font-size: 18px;
  color: #222222;
}
.shorting-menu button.filter.active {
  color: #ef8017;
}

/*** footer style **/
.footer-area {
  background-color: #eaecf1;
  background: linear-gradient(to right, #2ca69d 0%, #60cac5 50%, #e75562 100%);
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.24);
}
.footer-area.footer-area-two {
  background-color: #eaecf1;
}

.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget img {
  margin-bottom: 30px;
}
.single-footer-widget .address {
  position: relative;
  padding-left: 75px;
}
.single-footer-widget .address span {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  color: #222222;
}
.single-footer-widget h3 {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.single-footer-widget h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 2px;
  background-color: #fff;
}
.single-footer-widget ul li {
  margin-bottom: 10px;
}
.single-footer-widget ul li span {
  font-weight: 500;
  color: #222222;
}
.single-footer-widget ul li:last-child {
  margin-bottom: 0;
}
.single-footer-widget ul li a:hover {
  margin-left: 5px;
}
.single-footer-widget .open-day {
  margin-top: 15px;
}
.single-footer-widget .open-day li span {
  float: right;
  font-weight: normal;
  color: #666666;
  text-transform: uppercase;
}

.footer-area p, .footer-area ul li a {
  color: #000;
}

.footer-area ul li a:hover {
  color: #fff;
}

.footer-area ul li {
  list-style-type: none;
}

/*
Copy Right Area Style
======================================================*/
.copy-right-area {
  background-color: #ffffff;
  border-top: 1px solid #e7eaf0;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.copy-right-area p a {
  color: #ef8017;
}
.copy-right-area.copy-right-area-two {
  background-color: #eaecf1;
  border-top: 1px solid #bfc7d7;
}

.headingtop {
  border: 1px solid #e4e5e7;
  border-radius: 5px;
}

.card1 {
  border-top: none !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin: 0 4px;
  margin-bottom: 10px;
}

.card-body h4 {
  font-size: 1.2rem;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}
@media only screen and (max-width: 768px) {
  .leftoffset {
    margin-left: 0;
  }

  .modal-media {
    width: 95% !important;
    left: 5% !important;
  }
}
.pagination-area {
  margin-top: 10px;
  text-align: center;
}
.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #222222;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 17px;
  background-color: #f5f6fa;
  border: 1px solid #d7d7d7;
}
.pagination-area .page-numbers:hover {
  color: #ffffff;
  border-color: #ef8017;
  background-color: #ef8017;
}
.pagination-area .page-numbers i {
  position: relative;
  font-size: 25px;
  top: 5px;
}
.pagination-area .page-numbers.active {
  color: #ffffff;
  border-color: #ef8017;
  background-color: #ef8017;
}

.page-title-area {
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  background-color: #eaecf1;
}
.page-title-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(assets/img/page-bg/page-bg-shape.png);
  animation: FocuS 5s linear 1s infinite;
  z-index: -1;
}
.page-title-area .page-title-content {
  position: relative;
  margin-top: -10px;
}
.page-title-area .page-title-content h2 {
  margin-bottom: 15px;
  color: #222222;
  font-size: 30px;
}
.page-title-area .page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: -5px;
}
.page-title-area .page-title-content ul li {
  display: inline-block;
  position: relative;
  font-size: 16px;
  padding-right: 15px;
  margin-left: 15px;
  font-weight: 500;
}
.page-title-area .page-title-content ul li::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -3px;
  background-color: #ef8017;
  width: 1px;
  height: 15px;
  transform: rotate(25deg);
}
.page-title-area .page-title-content ul li:last-child::before {
  display: none;
}
.page-title-area .page-title-content ul li a {
  color: #666666;
}
.page-title-area .page-title-content ul li a:hover {
  color: #ef8017;
}
.page-title-area .page-title-content ul .active {
  color: #ef8017;
}

.packagecard p {
  line-height: 1.2;
}

.page-numbers a:hover {
  color: #fff !important;
}

.headingcolor {
  color: #079ca3;
}

.deletebtn:hover {
  border: 1px solid #ef8017;
  background-color: #ef8017 !important;
}

.modal-header {
  background: linear-gradient(to right, #2ca59d 0%, #33c8c1 50%, #14d397 100%);
}

.modal-title {
  color: #fff;
}

.modal-content {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.5);
  border: none !important;
  border-radius: 4px;
  margin: 20px;
  width: 94%;
}

.text-danger {
  font-size: 14px;
  padding-top: 3px;
}

/*
Go Top Style*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 82%;
  right: -10%;
  background-color: #ef8017;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
}
.go-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  transition: 0.5s;
  font-size: 20px;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #ef8017;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.go-top:hover {
  color: #ffffff;
  background-color: #ef8017;
}
.go-top:hover::before {
  opacity: 1;
  visibility: visible;
}
.go-top:hover i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.go-top:focus {
  color: #ffffff;
}
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.go-top.active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  right: 3%;
  top: 82%;
}

.packagecard {
  text-align: left;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px 3px;
  transition: all ease 0.5s;
  margin-bottom: 30px;
}
.packagecard:hover {
  transform: translateY(-5px);
  background-color: #f1eeea;
}

@keyframes FocuS {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* 
Contact Area Style
=====================================================*/
.contact-area .address-and-week {
  position: unset;
  max-width: unset;
}
.contact-area .address-and-week .address-week .week li {
  margin-bottom: 12px;
  padding-bottom: 12px;
}
.contact-area .address-and-week .address-week .address li {
  margin-bottom: 20px;
}

.contact-form {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.contact-form .contact-title {
  margin-bottom: 30px;
}
.contact-form .form-group {
  margin-bottom: 30px;
}

.list-unstyled {
  color: #dc3545;
  font-size: 14px;
  margin-top: 10px;
}

#msgSubmit {
  margin-top: 20px;
}

.text-success {
  color: #28a745;
}

.hidden {
  display: none;
}

.map-area iframe {
  width: 100%;
  height: 500px;
  border: none;
  overflow: hidden;
  display: block;
}

/* 
Online Price Area Style
=====================================================*/
.online-price-img {
  background-image: url(assets/img/online-price-img.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.online-price-list {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 40px;
}
.online-price-list ul li {
  font-weight: 500;
  margin-bottom: 30px;
}
.online-price-list ul li:last-child {
  margin-bottom: 0;
}
.online-price-list ul li .one {
  float: right;
  margin-left: 30px;
}
.online-price-list ul li .two {
  float: right;
}

/*
FAQ Area Style
======================================================*/
.faq-area .section-title {
  text-align: left;
  max-width: unset;
  margin-bottom: 30px;
}

.faq-bg {
  max-width: 700px;
  margin: auto;
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 10px;
}
.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item.active {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-title {
  padding: 15px 30px 15px 0;
  color: #222222;
  text-decoration: none;
  position: relative;
  display: block;
  border-bottom: 1px solid #e7e7e7;
  font-size: 18px;
  font-weight: 600;
}
.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: unset;
  right: 0;
  top: 17px;
  font-size: 25px;
  transition: all ease 0.5s;
  background-color: #ef8017;
  color: #ffffff;
  border-radius: 50%;
}
.faq-accordion .accordion .accordion-title.active i::before {
  content: "";
}
.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: -5px;
  padding: 30px;
  padding-bottom: 10px;
  padding-left: 0;
}
.faq-accordion .accordion .accordion-content span {
  font-weight: 500;
  color: #222222;
}
.faq-accordion .accordion .accordion-content p {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-content.show {
  display: block;
}

/*
Privacy Policy Area Style
=====================================================*/
.privacy-policy {
  max-width: 800px;
  margin: auto;
}
.privacy-policy .title {
  margin-bottom: 30px;
  text-align: center;
}
.privacy-policy .title h2 {
  font-size: 36px;
}
.privacy-policy .privacy-content {
  margin-bottom: 30px;
}
.privacy-policy .privacy-content h3 {
  margin-bottom: 15px;
}
.privacy-policy .privacy-content p {
  margin-bottom: 10px;
}
.privacy-policy .privacy-content.eight {
  margin-bottom: 0;
}
.privacy-policy .privacy-content.eight p {
  margin-bottom: 0;
}

/*
Terms Conditions Area Style
=====================================================*/
.terms-conditions {
  max-width: 800px;
  margin: auto;
}
.terms-conditions .title {
  margin-bottom: 30px;
  text-align: center;
}
.terms-conditions .title h2 {
  font-size: 36px;
}
.terms-conditions img {
  margin-bottom: 30px;
}
.terms-conditions .conditions-content {
  margin-bottom: 30px;
}
.terms-conditions .conditions-content h3 {
  margin-bottom: 15px;
}
.terms-conditions .conditions-content p {
  margin-bottom: 10px;
}
.terms-conditions .conditions-content ul {
  margin-left: 20px;
  margin-bottom: 15px;
  list-style-type: disc;
  margin-top: 15px;
}
.terms-conditions .conditions-content ul li {
  margin-bottom: 10px;
}
.terms-conditions .conditions-content ul li:last-child {
  margin-bottom: 0;
}
.terms-conditions .conditions-content.six {
  margin-bottom: 0;
}
.terms-conditions .conditions-content.six p {
  margin-bottom: 0;
}

/*
Coming Soon Area CSS
=====================================================*/
.coming-soon-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background-image: url(assets/img/coming-soon-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}
.coming-soon-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b0bacf;
  opacity: 0.5;
  z-index: -1;
}
.coming-soon-area .coming-soon-content {
  max-width: 700px;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 40px 60px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  position: relative;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 0.9);
}
.coming-soon-area .coming-soon-content .logo {
  display: inline-block;
}
.coming-soon-area .coming-soon-content h2 {
  font-size: 48px;
  margin-top: 30px;
  margin-bottom: 0;
}
.coming-soon-area .coming-soon-content #timer {
  margin-top: 40px;
}
.coming-soon-area .coming-soon-content #timer div {
  background-color: #222222;
  color: #ffffff;
  width: 100px;
  height: 105px;
  border-radius: 5px;
  font-size: 40px;
  font-weight: 800;
  margin-left: 10px;
  margin-right: 10px;
}
.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  margin-top: -2px;
  font-size: 17px;
  font-weight: 600;
}
.coming-soon-area .coming-soon-content form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.coming-soon-area .coming-soon-content form .form-group {
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}
.coming-soon-area .coming-soon-content form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #222222;
}
.coming-soon-area .coming-soon-content form .form-group .label-title i {
  position: absolute;
  left: 0;
  transition: all ease 0.5s;
  top: 9px;
  font-size: 22px;
}
.coming-soon-area .coming-soon-content form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: all ease 0.5s;
  background: #ef8017;
}
.coming-soon-area .coming-soon-content form .form-group .input-newsletter {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #eeeeee;
  padding: 0 0 0 32px;
  color: #222222;
  height: 45px;
  display: block;
  width: 100%;
  transition: all ease 0.5s;
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
}
.coming-soon-area .coming-soon-content form .form-group .input-newsletter::placeholder {
  color: #666666;
  transition: all ease 0.5s;
}
.coming-soon-area .coming-soon-content form .form-group .input-newsletter:focus {
  border-color: #ef8017;
  outline: 0 !important;
}
.coming-soon-area .coming-soon-content form .form-group .input-newsletter:focus::placeholder {
  color: transparent;
}
.coming-soon-area .coming-soon-content form .default-btn {
  border-radius: 0;
  position: relative;
}
.coming-soon-area .coming-soon-content form .default-btn::before {
  border-radius: 0;
}
.coming-soon-area .coming-soon-content form .validation-danger {
  margin-top: 15px;
  color: #ff0000;
}
.coming-soon-area .coming-soon-content form .validation-success {
  margin-top: 15px;
}
.coming-soon-area .coming-soon-content form p {
  margin-bottom: 0;
  margin-top: 20px;
}
.coming-soon-area .online-treatment-shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}
.coming-soon-area .online-treatment-shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

/*
404 Error Area Style
=====================================================*/
.error-area {
  text-align: center;
  position: relative;
  z-index: 1;
}
.error-area .error-content-wrap {
  z-index: 1;
  position: relative;
}
.error-area .error-content-wrap h1 {
  font-size: 300px;
  line-height: 1;
  font-weight: 700;
  color: #ef8017;
}
.error-area .error-content-wrap h1 .a {
  display: inline-block;
}
.error-area .error-content-wrap h1 .red {
  color: #ff0000;
  display: inline-block;
}
.error-area .error-content-wrap h1 .b {
  display: inline-block;
}
.error-area .error-content-wrap h3 {
  margin: 30px 0 0;
  position: relative;
  color: #ff0000;
}
.error-area .error-content-wrap p {
  margin: 20px 0 20px;
  font-size: 19px;
  color: #ef8017;
}

/*
User Area CSS
=====================================================*/
.user-area-style {
  position: relative;
  z-index: 1;
}
.user-area-style .contact-form-action {
  max-width: 600px;
  border-bottom: none;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 30px;
  margin: auto;
}
.user-area-style .contact-form-action .account-title {
  margin-bottom: 30px;
}
.user-area-style .contact-form-action .account-title h2 {
  font-size: 30px;
  position: relative;
  line-height: 1;
  padding-left: 0;
  padding-bottom: 15px;
}
.user-area-style .contact-form-action .account-title h2::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 2px;
  background-color: #ef8017;
}
.user-area-style .contact-form-action form .form-condition {
  margin-bottom: 20px;
}
.user-area-style .contact-form-action form .form-condition .agree-label {
  font-weight: 600;
}
.user-area-style .contact-form-action form .form-condition .agree-label a {
  color: #ef8017;
}
.user-area-style .contact-form-action form .form-group {
  margin-bottom: 30px;
}
.user-area-style .contact-form-action form .login-action {
  margin-bottom: 30px;
}
.user-area-style .contact-form-action form .login-action .log-rem {
  display: inline-block;
}
.user-area-style .contact-form-action form .login-action .log-rem label {
  margin-bottom: 0;
}
.user-area-style .contact-form-action form .login-action .forgot-login {
  display: inline-block;
  float: right;
}
.user-area-style .contact-form-action p {
  margin-top: 25px;
  line-height: 1;
}
.user-area-style .contact-form-action p a {
  color: #222222;
}
.user-area-style .contact-form-action p a:hover {
  color: #ef8017;
}
.user-area-style.recover-password-area .recover {
  max-width: 600px;
  margin: auto;
  border-top: 1px solid #ebebeb;
  padding-top: 30px;
}
.user-area-style.recover-password-area .recover .default-btn {
  margin-bottom: 0;
  margin-top: 30px;
}
.user-area-style.recover-password-area h3 {
  font-size: 40px;
  margin-top: -11px;
}
.user-area-style.recover-password-area p {
  margin-bottom: 30px;
  line-height: 1.8;
}
.user-area-style.recover-password-area .now-register {
  float: right;
  margin: 0;
}

/*
Appointment Area CSS
=====================================================*/
.patient-information h3 {
  margin-bottom: 25px;
}
.patient-information .appointment-box {
  position: unset;
}
.patient-information .appointment-box .appointment-form {
  background-color: #ffffff;
}
.patient-information .checkbox-content .form-group {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 15px;
}
.patient-information .checkbox-content .form-group span {
  margin-left: 5px;
}
.patient-information .default-btn {
  margin-top: 10px;
}

/*
Product Area Style
======================================================*/
.showing-result {
  margin-bottom: 30px;
  margin-right: 15px;
  background-color: #eaecf1;
  padding: 5px;
}
.showing-result .showing-top-bar-ordering {
  text-align: right;
}
.showing-result .showing-top-bar-ordering .nice-select {
  color: #ef8017;
  border-radius: 0;
  border: none;
  float: unset;
  height: unset;
  line-height: initial;
  padding: 15px 35px 15px 15px;
  font-size: 16px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.showing-result .showing-top-bar-ordering .nice-select .list {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 250px;
  overflow-y: scroll;
}
.showing-result .showing-top-bar-ordering .nice-select .list .option {
  transition: all ease 0.5s;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}
.showing-result .showing-top-bar-ordering .nice-select .list .option:hover {
  background-color: #ef8017 !important;
  color: #ffffff;
}
.showing-result .showing-top-bar-ordering .nice-select .list .option.selected {
  font-weight: 500;
  color: #666666;
}
.showing-result .showing-top-bar-ordering .nice-select:after {
  right: 20px;
}
.showing-result .search-form .search-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #ef8017;
  color: #ffffff;
  font-size: 20px;
}
.showing-result .search-form .form-control {
  border: 1px solid #d7d7d7;
  background-color: #f5f6fa;
  font-size: 16px;
}
.showing-result .search-form .form-control::placeholder {
  color: #616161;
}

.single-product {
  text-align: center;
  margin-bottom: 30px;
}
.single-product .product-img {
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.single-product .product-img ul {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  transform: scaleY(0);
  transition: all ease 0.5s;
}
.single-product .product-img ul li {
  display: inline-block;
  margin: 0 5px;
}
.single-product .product-img ul li a i {
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  color: #222222;
  border-radius: 4px;
  transition: all ease 0.5s;
}
.single-product .product-img ul li a:hover i {
  background-color: #ef8017;
  color: #ffffff;
}
.single-product .product-img .default-btn {
  bottom: 30px;
  transform: scaleX(0);
  position: absolute;
  left: 0;
  right: 0;
  width: 190px;
  text-align: center;
  margin: auto;
}
.single-product .product-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ef8017;
  opacity: 0.5;
  transform: scaleY(0);
  transition: all ease 0.5s;
}
.single-product a h3 {
  font-size: 20px;
  transition: all ease 0.5s;
  margin-bottom: 10px;
}
.single-product a:hover h3 {
  color: #ef8017;
}
.single-product span {
  font-size: 16px;
  display: block;
  margin-bottom: 6px;
}
.single-product span del {
  margin-right: 10px;
}
.single-product:hover .product-img ul {
  transform: scaleY(1);
}
.single-product:hover .product-img::before {
  transform: scaleY(1);
}
.single-product:hover .product-img .default-btn {
  transform: scaleX(1);
}

/*
product-view-one
=================================================*/
.product-view-one {
  padding-right: 0 !important;
}
.product-view-one.modal.show .modal-dialog {
  margin-top: 25px;
}
.product-view-one .product-view-one-image {
  background-color: #eeeeee;
  padding: 30px;
}
.product-view-one .modal-dialog {
  max-width: 900px;
  margin: 0 auto;
}
.product-view-one .modal-content {
  border: none !important;
  padding: 40px;
  border-radius: 0 !important;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.15);
}
.product-view-one .modal-content button.close {
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  opacity: 1;
  color: #222222;
  transition: 0.5s;
  line-height: 35px;
  padding: 0;
  margin: 0;
  font-size: 20px;
  width: 35px;
  text-shadow: unset;
  height: 35px;
}
.product-view-one .modal-content button.close:hover {
  background-color: #ff0000;
  color: #ffffff;
}
.product-view-one .modal-content .product-content h3 {
  font-size: 22px;
  margin-bottom: 14px;
}
.product-view-one .modal-content .product-content h3 a {
  color: #222222;
}
.product-view-one .modal-content .product-content h3 a:hover {
  color: #222222;
}
.product-view-one .modal-content .product-content .price {
  margin-bottom: 10px;
}
.product-view-one .modal-content .product-content .price span {
  display: inline-block;
  font-size: 18px;
  color: #222222;
}
.product-view-one .modal-content .product-content .price del {
  display: inline-block;
  font-size: 18px;
  color: #c1c1c1;
  margin-left: 5px;
}
.product-view-one .modal-content .product-content .product-review {
  margin-bottom: 10px;
}
.product-view-one .modal-content .product-content .product-review .rating {
  display: inline-block;
  padding-right: 5px;
}
.product-view-one .modal-content .product-content .product-review .rating i {
  color: #ef8017;
}
.product-view-one .modal-content .product-content .product-review .rating-count {
  display: inline-block;
  color: #222222;
  border-bottom: 1px solid #222222;
  line-height: initial;
}
.product-view-one .modal-content .product-content .product-info {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.product-view-one .modal-content .product-content .product-info li {
  font-size: 16px;
  color: #222222;
  margin-bottom: 10px;
}
.product-view-one .modal-content .product-content .product-info li:last-child {
  margin-bottom: 0;
}
.product-view-one .modal-content .product-content .product-info li span {
  color: #222222;
}
.product-view-one .modal-content .product-content .product-info li a {
  display: inline-block;
  font-weight: 500;
  color: #222222;
}
.product-view-one .modal-content .product-content .product-info li a:hover {
  color: #222222;
}
.product-view-one .modal-content .product-content .product-color-switch {
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-view-one .modal-content .product-content .product-color-switch h4 {
  font-size: 16px;
  color: #222222;
  margin-bottom: 6px;
}
.product-view-one .modal-content .product-content .product-color-switch ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -4px;
  margin-left: -4px;
  padding-left: 0;
}
.product-view-one .modal-content .product-content .product-color-switch ul li {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a {
  display: inline-block;
  position: relative;
  border: 1px solid transparent;
  width: 25px;
  height: 25px;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  margin: 2px;
  transition: 0.5s;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a:hover {
  border-color: #222222;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a:focus {
  border-color: #222222;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-white::before {
  background: blue;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-black::before {
  background: #222222;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-green::before {
  background: green;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-yellowgreen::before {
  background: yellowgreen;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-teal::before {
  background: teal;
}
.product-view-one .modal-content .product-content .product-color-switch ul li.active a {
  border-color: #222222;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #666666;
  width: 40px;
  height: 100%;
  line-height: 50px;
  transition: all ease 0.5s;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span:hover {
  color: #222222;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span.minus-btn {
  left: 0;
  transition: all ease 0.5s;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span.minus-btn:hover {
  background-color: #ef8017;
  color: #ffffff;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span.plus-btn {
  right: 0;
  transition: all ease 0.5s;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span.plus-btn:hover {
  background-color: #ef8017;
  color: #ffffff;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter input {
  height: 48px;
  color: #222222;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter input::placeholder {
  color: #222222;
}
.product-view-one .modal-content .product-content .share-this-product h3 {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 10px;
}
.product-view-one .modal-content .product-content .share-this-product ul li {
  display: inline-block;
  margin-right: 3px;
}
.product-view-one .modal-content .product-content .share-this-product ul li a i {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  background-color: #eeeeee;
  border-radius: 4px;
  text-align: center;
  transition: all ease 0.5s;
}
.product-view-one .modal-content .product-content .share-this-product ul li a:hover i {
  background-color: #ef8017;
  color: #ffffff;
}
.product-view-one .owl-carousel .owl-item img {
  width: unset;
  margin: auto;
}
.product-view-one #big .item {
  padding: 30px;
  background-color: #ffffff;
  margin-bottom: 30px;
}
.product-view-one .owl-item.active.current {
  background-color: #ef8017 !important;
  padding: 5px;
}
.product-view-one.fade {
  animation: zoomIn 1s linear;
}
.product-view-one .owl-next {
  position: absolute;
  top: 50%;
  right: 50px;
  margin: 0 !important;
  border-radius: 0 !important;
  transition: all ease 0.5s;
  opacity: 0;
  transform: translateY(-25px);
}
.product-view-one .owl-next i {
  background-color: #ef8017;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  transition: all ease 0.5s;
  border-radius: 4px;
  font-size: 15px;
}
.product-view-one .owl-prev {
  position: absolute;
  top: 50%;
  left: 50px;
  margin: 0 !important;
  border-radius: 0 !important;
  transition: all ease 0.5s;
  opacity: 0;
  transform: translateY(-25px);
}
.product-view-one .owl-prev i {
  background-color: #ef8017 !important;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  transition: all ease 0.5s;
  font-size: 15px;
  border-radius: 4px;
}
.product-view-one:hover .owl-prev {
  opacity: 1;
  left: 0;
}
.product-view-one:hover .owl-next {
  opacity: 1;
  right: 0;
}
.product-view-one .owl-theme .owl-nav {
  margin-top: 0 !important;
}
.product-view-one .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}

/*
Shopping Cart Area Style
======================================================*/
.read, .notype {
  list-style-type: none;
}

.highlight-test li {
  line-height: 1.2 !important;
}

.transitionimg img {
  transition: 1s ease;
}

.transitionimg img:hover {
  /* opacity: 0.5; */
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}

.flexcontent {
  display: flex;
  flex-wrap: wrap;
}

.shopping-cart-area .cart-controller {
  max-width: 810px;
  margin: auto;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 30px;
}
.shopping-cart-area .product-thumbnail {
  width: 130px;
}
.shopping-cart-area .cart-table {
  text-align: center;
}
.shopping-cart-area .cart-table table thead tr th {
  border-bottom: none;
  border: none;
  border-bottom: 1px solid #dedede;
  color: #222222;
  padding-top: 0;
  font-size: 16px;
  font-weight: 600;
}
.shopping-cart-area .cart-table table tbody tr td {
  vertical-align: middle;
  border: none;
  border-bottom: 1px solid #dedede;
}
.shopping-cart-area .cart-table table tbody tr td a {
  color: #222222;
}
.shopping-cart-area .cart-table table tbody tr td a:hover {
  color: #ef8017;
}
.shopping-cart-area .cart-table table tbody tr td.product-subtotal {
  font-size: 15px;
}
.shopping-cart-area .cart-table table tbody tr td.product-subtotal .remove {
  margin-left: 50px;
  width: 30px;
  height: 30px;
  line-height: 34px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 20px;
  transition: all ease 0.5s;
  color: #666666;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  float: right;
}
.shopping-cart-area .cart-table table tbody tr td.product-subtotal .remove:hover {
  background-color: #ff0000;
  color: #ffffff;
}
.shopping-cart-area .cart-table table tbody tr td.product-quantity {
  width: 145px;
}
.shopping-cart-area .cart-table table tbody tr td.product-quantity .input-counter {
  position: relative;
}
.shopping-cart-area .cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: #222222;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}
.shopping-cart-area .cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: #222222;
}
.shopping-cart-area .cart-table table tbody tr td.product-quantity .input-counter .minus-btn {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  cursor: pointer;
  color: #222222;
  width: 40px;
  height: 100%;
  line-height: 48px;
  transition: all ease 0.5s;
  border: 1px solid #dedede;
}
.shopping-cart-area .cart-table table tbody tr td.product-quantity .input-counter .minus-btn:hover {
  color: #ffffff;
  background-color: #ef8017;
}
.shopping-cart-area .cart-table table tbody tr td.product-quantity .input-counter .plus-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  cursor: pointer;
  color: #222222;
  width: 40px;
  height: 100%;
  line-height: 48px;
  transition: all ease 0.5s;
  border: 1px solid #dedede;
}
.shopping-cart-area .cart-table table tbody tr td.product-quantity .input-counter .plus-btn:hover {
  color: #ffffff;
  background-color: #ef8017;
}
.shopping-cart-area .coupon-cart {
  margin-top: 20px;
}
.shopping-cart-area .coupon-cart .form-group {
  position: relative;
}
.shopping-cart-area .coupon-cart .form-group .form-control {
  border: 1px solid #dedede;
}
.shopping-cart-area .coupon-cart .form-group .form-control:focus {
  border-color: #ef8017;
}
.shopping-cart-area .coupon-cart .form-group .default-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
}
.shopping-cart-area .coupon-cart .default-btn.update {
  background-color: transparent;
  border-color: #222222;
  color: #222222;
}
.shopping-cart-area .coupon-cart .default-btn.update:hover {
  background-color: #ef8017;
  border-color: #ef8017;
  color: #ffffff;
}
.shopping-cart-area .cart-totals {
  padding: 30px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 390px;
  margin: 40px auto 0;
}
.shopping-cart-area .cart-totals h3 {
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 10px;
}
.shopping-cart-area .cart-totals h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 150px;
  height: 2px;
  background-color: #ef8017;
}
.shopping-cart-area .cart-totals ul {
  padding: 0;
  margin: 0 0 25px;
  list-style-type: none;
}
.shopping-cart-area .cart-totals ul li {
  color: #666666;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dashed #dedede;
}
.shopping-cart-area .cart-totals ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}
.shopping-cart-area .cart-totals ul li span {
  float: right;
  color: #666666;
  font-weight: normal;
}
.shopping-cart-area .cart-totals .default-btn {
  display: block;
}

/* 
Checkout Area Style
=====================================================*/
.checkout-area .billing-details {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.checkout-area .billing-details h3 {
  margin-bottom: 20px;
  font-size: 30px;
}
.checkout-area .billing-details .form-group {
  margin-bottom: 20px;
}
.checkout-area .billing-details .form-group label {
  display: block;
  color: #666666;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
}
.checkout-area .billing-details .form-group label .required {
  color: #666666;
}
.checkout-area .billing-details .form-group .nice-select {
  float: unset;
  line-height: 45px;
  color: #666666;
  padding-top: 0;
  padding-bottom: 0;
}
.checkout-area .billing-details .form-group .nice-select .list {
  background-color: #ffffff;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.checkout-area .billing-details .form-group .nice-select .list .option {
  transition: 0.5s;
  padding-left: 20px;
  padding-right: 20px;
}
.checkout-area .billing-details .form-group .nice-select .list .option:hover {
  background-color: #ef8017 !important;
  color: #ffffff;
}
.checkout-area .billing-details .form-group .nice-select .list .option.selected {
  background-color: transparent;
}
.checkout-area .billing-details .form-group .nice-select:after {
  right: 20px;
}
.checkout-area .billing-details .form-group .nice-select .option:hover,
.checkout-area .billing-details .form-group .nice-select .option.focus,
.checkout-area .billing-details .form-group .nice-select .option.selected.focus {
  background-color: #ef8017 !important;
  color: #ffffff !important;
}
.checkout-area .billing-details .form-group textarea {
  padding-top: 13px;
}
.checkout-area .billing-details .form-check {
  margin-bottom: 20px;
}
.checkout-area .billing-details .form-check .form-check-label {
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}
.checkout-area .billing-details .form-check label {
  position: relative;
  top: 1px;
}
.checkout-area .billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}
.checkout-area .cart-totals {
  margin-bottom: 30px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
  padding: 30px;
  background-color: #ffffff;
}
.checkout-area .cart-totals h3 {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 10px;
}
.checkout-area .cart-totals h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 150px;
  height: 2px;
  background-color: #ef8017;
}
.checkout-area .cart-totals ul li {
  color: #666666;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dashed #dedede;
}
.checkout-area .cart-totals ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.checkout-area .cart-totals ul li span {
  float: right;
  color: #666666;
  font-weight: normal;
}
.checkout-area .faq-accordion {
  margin-bottom: 30px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 30px;
  margin-bottom: 0;
}
.checkout-area .faq-accordion h3 {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 10px;
}
.checkout-area .faq-accordion h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 150px;
  height: 2px;
  background-color: #ef8017;
}
.checkout-area .faq-accordion .accordion .accordion-content {
  border-top: none;
  padding: 0;
}
.checkout-area .faq-accordion .accordion .accordion-title {
  padding: 0;
  position: relative;
  padding-left: 25px;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  color: #222222;
  font-weight: 500;
}
.checkout-area .faq-accordion .accordion .accordion-title.active::before {
  background-color: #ef8017;
}
.checkout-area .faq-accordion .accordion .accordion-title.active::after {
  background-color: #ef8017;
}
.checkout-area .faq-accordion .accordion .accordion-title::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #222222;
  border-radius: 50%;
}
.checkout-area .faq-accordion .accordion .accordion-title::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 2px;
  width: 11px;
  height: 11px;
  background-color: #ffffff;
  border-radius: 50%;
  border-radius: 50%;
}
.checkout-area .faq-accordion .accordion .accordion-title:hover {
  color: #ef8017;
}
.checkout-area .faq-accordion .accordion .accordion-item {
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 15px;
}
.checkout-area .faq-accordion .form-check {
  font-size: 14px;
}
.checkout-area .faq-accordion .form-check label {
  margin-bottom: 5px;
}
.checkout-area .faq-accordion .form-check label a {
  color: #ef8017;
}
.checkout-area .faq-accordion .form-check label a:hover {
  color: #ef8017;
}

.shortborder {
  height: 2px;
  margin-top: 5px;
  margin-bottom: 15px;
  width: 130px;
  background-color: #079ca3;
}

.shortborder2 {
  height: 4px;
  margin: 5px auto 15px auto;
  text-align: center;
  width: 193px;
  background: linear-gradient(to left, #12d495 0%, #33c8c1 50%, #2a9c92 100%);
}

/* 
Wishlist Area Style
=====================================================*/
.wishlist {
  max-width: 1080px;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.wishlist .default-btn {
  float: right;
  margin-top: -11px;
  color: #ffffff !important;
  position: relative;
  top: 6px;
}
.wishlist .default-btn:hover {
  color: #ef8017 !important;
}
.wishlist .table-responsive > .table-bordered {
  margin-bottom: 0;
}

/*
Shop Details Area Style
=====================================================*/
.product-details-area .product-details-desc h3 {
  margin-bottom: 20px;
  font-size: 25px;
}
.product-details-area .product-details-desc p {
  margin-bottom: 0;
}
.product-details-area .product-details-desc .price {
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #eeeeee;
}
.product-details-area .product-details-desc .price span {
  padding: 0 5px;
}
.product-details-area .product-details-desc .price del {
  color: #ef8017;
  margin-right: 10px;
}
.product-details-area .product-details-desc .product-add-to-cart {
  margin-bottom: 20px;
  border-top: 1px solid #eeeeee;
  padding-top: 20px;
  margin-top: 20px;
}
.product-details-area .product-details-desc .product-add-to-cart h3 {
  display: inline-block;
  margin-right: 10px;
  font-size: 17px;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
  border: 1px solid #cfcfcf;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 34px;
  transition: 0.5s;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter span:hover {
  color: #ef8017;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter span.minus-btn {
  left: 0;
  border-right: 1px solid #cfcfcf;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter span.plus-btn {
  right: 0;
  border-left: 1px solid #cfcfcf;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter input {
  height: 30px;
  color: #333333;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter input::placeholder {
  color: #333333;
}
.product-details-area .product-details-desc .social-wrap {
  margin-top: 20px;
}
.product-details-area .product-details-desc .social-wrap li {
  display: inline-block;
  padding-right: 10px;
  font-size: 17px;
  font-weight: 600;
  color: #222222;
}
.product-details-area .product-details-desc .social-wrap li span {
  display: inline-block;
  margin-right: 45px;
}
.product-details-area .product-details-desc .social-wrap li a i {
  font-size: 20px;
  line-height: 1;
  color: #222222;
  transition: all ease 0.5s;
}
.product-details-area .product-details-desc .social-wrap li a:hover i {
  color: #ef8017;
}
.product-details-area .product-details-desc .default-btn {
  margin-bottom: 0 !important;
}
.product-details-area .product-details-desc .default-btn i {
  position: relative;
  top: -1px;
  margin-right: 5px;
}
.product-details-area .product-details-desc .category {
  font-size: 17px;
  font-weight: 600;
  margin-top: 15px;
}
.product-details-area .product-details-desc .category span {
  font-weight: normal;
}
.product-details-area .product-details-desc .product-review {
  position: relative;
  margin-bottom: 10px;
}
.product-details-area .product-details-desc .product-review .rating i {
  color: #ffba0a;
}
.product-details-area .product-details-desc .product-review .rating-count {
  position: absolute;
  top: 0;
  left: 110px;
}
.product-details-area .product-details-image {
  text-align: center;
  background-color: #f7f7f7;
}
.product-details-area .tab .tabs_item {
  display: none;
}
.product-details-area .tab .tabs_item:first-child {
  display: block;
}
.product-details-area .products-details-tab {
  margin-top: 50px;
}
.product-details-area .products-details-tab .tabs {
  list-style-type: none;
  padding-left: 0;
  text-align: center;
  border-bottom: 1px solid #eaedff;
}
.product-details-area .products-details-tab .tabs li {
  display: inline-block;
  line-height: initial;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 20px;
}
.product-details-area .products-details-tab .tabs li a {
  display: inline-block;
  position: relative;
  color: #333333;
  border-bottom: none;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
}
.product-details-area .products-details-tab .tabs li a:hover {
  color: #ef8017;
}
.product-details-area .products-details-tab .tabs li.current a {
  color: #ef8017;
}
.product-details-area .products-details-tab .tab_content {
  padding: 30px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content p {
  margin-bottom: 20px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content p:last-child {
  margin-bottom: 0;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: left;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li {
  border: 1px solid #eaedff;
  border-bottom: none;
  padding: 10px 15px;
  color: #57647c;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li:last-child {
  border-bottom: 1px solid #eaedff;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li span {
  display: inline-block;
  width: 30%;
  color: #222222;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content h3 {
  margin-bottom: 15px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title {
  position: relative;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating {
  display: inline-block;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating .fas.fa-star {
  color: #ffba0a;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating i {
  color: #ffba0a;
  font-size: 14px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 5px;
  line-height: initial;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .btn {
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 10px 11px;
  font-size: 11px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .btn:focus {
  border: none !important;
  box-shadow: none !important;
  border: 4px;
  background-color: #ef8017;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments {
  margin-top: 35px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
  margin-top: 30px;
  position: relative;
  padding-right: 200px;
  border-top: 1px dashed #eaedff;
  padding-top: 30px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating .fas.fa-star {
  color: #ffba0a;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating i {
  font-size: 14px;
  color: #ffba0a;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span {
  margin-bottom: 10px;
  font-size: 13px;
  display: block;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item p {
  margin-bottom: 0;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
  position: absolute;
  right: 0;
  color: #57647c;
  top: 40px;
  text-decoration: underline;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link:hover {
  color: #ff4800;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form {
  margin-top: 30px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group {
  margin-bottom: 20px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group label {
  color: #444444;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group textarea {
  padding-top: 15px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group .form-control {
  font-size: 14px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .btn:focus {
  box-shadow: none;
  border: none;
  border-radius: 0;
}

/*
Blog Details Area Style
======================================================*/
.blog-details-content .blog-top-content {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px;
}
.blog-details-content .blog-top-content .blog-details-img {
  margin-bottom: 30px;
}
.blog-details-content .blog-top-content .news-content h3 {
  transition: all ease 0.5s;
  margin-bottom: 20px;
  font-size: 25px;
}
.blog-details-content .blog-top-content .news-content .admin {
  padding-bottom: 24px;
  line-height: 1;
}
.blog-details-content .blog-top-content .news-content .admin li {
  display: inline-block;
  font-size: 16px;
  margin-right: 30px;
}
.blog-details-content .blog-top-content .news-content .admin li:last-child {
  margin-right: 0;
}
.blog-details-content .blog-top-content .news-content .admin li i {
  color: #ef8017;
  font-size: 18px;
  position: relative;
  top: 1px;
}
.blog-details-content .blog-top-content blockquote {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}
.blog-details-content .blog-top-content blockquote p {
  font-weight: 500;
  font-size: 18px;
  color: #222222;
  text-align: center;
  padding-left: 150px;
}
.blog-details-content .blog-top-content blockquote i {
  font-size: 60px;
  color: #ef8017;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
}
.blog-details-content .news-content-2 {
  margin-bottom: 30px;
}
.blog-details-content .news-content-2 h3 {
  font-size: 24px;
}
.blog-details-content .single-blog-post-img {
  margin-bottom: 30px;
}
.blog-details-content .social {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.blog-details-content .social span {
  margin-right: 10px;
  font-weight: 500;
}
.blog-details-content .social ul {
  display: inline-block;
  float: right;
}
.blog-details-content .social ul li {
  display: inline-block;
}
.blog-details-content .social ul li a i {
  font-size: 18px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  border: 1px solid #666666;
  text-align: center;
  transition: all ease 0.5s;
}
.blog-details-content .social ul li a:hover i {
  background-color: #ef8017;
  border-color: #ef8017;
  color: #ffffff;
}
.blog-details-content .social ul li:last-child a {
  margin-right: 0;
}
.blog-details-content .comments {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 35px;
}
.blog-details-content .comments h3 {
  margin-bottom: 30px;
  padding-bottom: 10px;
  font-size: 24px;
  position: relative;
  display: inline-block;
}
.blog-details-content .comments h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ef8017;
}
.blog-details-content .comments ul li {
  position: relative;
  padding-left: 100px;
  margin-bottom: 30px;
}
.blog-details-content .comments ul li:last-child {
  margin-bottom: 0;
}
.blog-details-content .comments ul li img {
  margin-bottom: 20px;
  position: absolute;
  left: 0;
  top: 0;
}
.blog-details-content .comments ul li h3 {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 16px;
  padding-left: 0;
}
.blog-details-content .comments ul li h3::before {
  display: none;
}
.blog-details-content .comments ul li span {
  display: block;
  margin-bottom: 15px;
}
.blog-details-content .comments ul li a {
  font-size: 14px;
  font-weight: 600;
}
.blog-details-content .comments ul li a:hover {
  letter-spacing: 1px;
}
.blog-details-content .comments ul li a i {
  margin-right: 5px;
}
.blog-details-content .leave-reply {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px;
}
.blog-details-content .leave-reply h3 {
  margin-bottom: 30px;
  font-size: 24px;
  position: relative;
  padding-bottom: 10px;
  display: inline-block;
}
.blog-details-content .leave-reply h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ef8017;
}
.blog-details-content .leave-reply p {
  margin-bottom: 30px;
}
.blog-details-content .leave-reply .form-group {
  margin-bottom: 25px;
}
.blog-details-content .leave-reply .form-group #chb1 {
  margin-right: 5px;
}
.blog-details-content .leave-reply .form-group label {
  margin-bottom: 10px;
}
.blog-details-content .leave-reply input[type=checkbox],
.blog-details-content .leave-reply input[type=radio] {
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.sidebar-widget {
  margin-bottom: 35px;
  position: relative;
  padding: 20px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}
.sidebar-widget h3 {
  font-size: 20px;
  margin-bottom: 20px;
  position: relative;
  margin-top: -5px;
  padding-bottom: 10px;
  display: inline-block;
}
.sidebar-widget h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ef8017;
}
.sidebar-widget.search .search-form .search-button {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #ef8017;
  top: 20px;
  right: 20px;
  font-size: 20px;
  color: #ffffff;
  transition: all ease 0.5s;
}
.sidebar-widget.search .search-form .search-button:hover {
  background-color: #222222;
}
.sidebar-widget.latest-news ul li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 20px;
}
.sidebar-widget.latest-news ul li::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #ef8017;
}
.sidebar-widget.latest-news ul li:last-child {
  margin-bottom: 0;
}
.sidebar-widget.latest-news ul li a {
  font-size: 17px;
  color: #222222;
  margin-bottom: 10px;
}
.sidebar-widget.latest-news ul li span {
  display: block;
  font-size: 14px;
}
.sidebar-widget.categories ul li {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 17px;
}
.sidebar-widget.categories ul li span {
  float: right;
  display: inline-block;
}
.sidebar-widget.categories ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.sidebar-widget.categories ul li:first-child::before {
  display: none;
}
.sidebar-widget.categories ul li:last-child {
  margin-bottom: 0;
}
.sidebar-widget.tags ul li {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
}
.sidebar-widget.tags ul li a {
  border: 1px solid #eeeeee;
  padding: 8px 12px;
}
.sidebar-widget.tags ul li a:hover {
  transform: translateY(-2px);
  background-color: #ef8017;
  color: #ffffff;
  border-color: #ef8017;
}
.sidebar-widget.tags ul li:last-child {
  margin-bottom: 0;
}
.sidebar-widget.gallery ul {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.sidebar-widget.gallery ul li {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.sidebar-widget.gallery ul li img {
  width: 100%;
}
.sidebar-widget.appointment-box {
  position: unset;
  padding: 0;
  box-shadow: none;
}

/*
Services Details Area Style
======================================================*/
.services-details-content .services-top-content {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px;
}
.services-details-content .services-top-content .services-details-img {
  margin-bottom: 30px;
}
.services-details-content .services-top-content .news-content h3 {
  transition: all ease 0.5s;
  margin-bottom: 15px;
  font-size: 25px;
}
.services-details-content .news-content-2 {
  margin-bottom: 30px;
  margin-top: 20px;
}
.services-details-content .news-content-2 h3 {
  font-size: 25px;
  margin-bottom: 15px;
}
.services-details-content .news-content-3 {
  margin-top: 20px;
}
.services-details-content .news-content-3 h3 {
  font-size: 25px;
  margin-bottom: 15px;
}
.services-details-content .news-content-3 ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.services-details-content .news-content-3 ul li:last-child {
  margin-bottom: 0;
}
.services-details-content .news-content-3 ul li i {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 25px;
  line-height: 1;
  color: #ef8017;
}
.services-details-content .single-services-post-img {
  margin-bottom: 30px;
}

/*
Portfolio Details Area Style
======================================================*/
.portfolio-details-content {
  max-width: 810px;
  margin: auto;
}
.portfolio-details-content .portfolio-top-content {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px;
}
.portfolio-details-content .portfolio-top-content .portfolio-details-img {
  margin-bottom: 30px;
}
.portfolio-details-content .portfolio-top-content .news-content h3 {
  transition: all ease 0.5s;
  margin-bottom: 15px;
  font-size: 25px;
}
.portfolio-details-content .news-content-2 {
  margin-bottom: 30px;
  margin-top: 20px;
}
.portfolio-details-content .news-content-2 h3 {
  font-size: 25px;
  margin-bottom: 15px;
}
.portfolio-details-content .news-content-3 {
  margin-top: 20px;
}
.portfolio-details-content .news-content-3 h3 {
  font-size: 25px;
  margin-bottom: 15px;
}
.portfolio-details-content .news-content-3 ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.portfolio-details-content .news-content-3 ul li:last-child {
  margin-bottom: 0;
}
.portfolio-details-content .news-content-3 ul li i {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 25px;
  line-height: 1;
  color: #ef8017;
}
.portfolio-details-content .single-portfolio-post-img {
  margin-bottom: 30px;
}

/*
Department Details Area Style
======================================================*/
.department-details-content .department-top-content {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px;
}
.department-details-content .department-top-content .department-details-img {
  margin-bottom: 30px;
}
.department-details-content .department-top-content .news-content h3 {
  transition: all ease 0.5s;
  margin-bottom: 15px;
  font-size: 25px;
}
.department-details-content .news-content-2 {
  margin-bottom: 30px;
  margin-top: 20px;
}
.department-details-content .news-content-2 h3 {
  font-size: 25px;
  margin-bottom: 15px;
}
.department-details-content .news-content-3 {
  margin-top: 20px;
}
.department-details-content .news-content-3 h3 {
  font-size: 25px;
  margin-bottom: 15px;
}
.department-details-content .news-content-3 ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.department-details-content .news-content-3 ul li:last-child {
  margin-bottom: 0;
}
.department-details-content .news-content-3 ul li i {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 25px;
  line-height: 1;
  color: #ef8017;
}
.department-details-content .single-department-post-img {
  margin-bottom: 30px;
}

/*
Doctors Details Area Style
=====================================================*/
.doctors-sidebar .doctors-img {
  text-align: center;
  margin-bottom: 30px;
}
.doctors-sidebar .doctors-img img {
  border-top: 3px solid #ef8017;
  border-left: 3px solid #ef8017;
  border-right: 3px solid #ef8017;
}
.doctors-sidebar .doctors-img ul {
  line-height: 1;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 30px;
}
.doctors-sidebar .doctors-img ul li {
  display: inline-block;
  margin: 0 5px;
}
.doctors-sidebar .doctors-img ul li a {
  width: 30px;
  height: 30px;
  line-height: 38px;
  background-color: #ef8017;
  color: #ffffff;
  border-radius: 4px;
  text-align: center;
}
.doctors-sidebar .doctors-img ul li a i {
  font-size: 20px;
}
.doctors-sidebar .doctors-img ul li a:hover {
  transform: translateY(-3px);
}
.doctors-sidebar .availability {
  box-shadow: #ffffff;
  background-color: #ffffff;
  padding: 30px;
  margin-bottom: 30px;
}
.doctors-sidebar .availability h3 {
  margin-bottom: 20px;
}
.doctors-sidebar .availability h3 i {
  margin-right: 5px;
  position: relative;
  top: 1px;
}
.doctors-sidebar .availability ul li {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.doctors-sidebar .availability ul li span {
  float: right;
}
.doctors-sidebar .availability ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.doctors-sidebar .appointment-box {
  position: unset;
}

.doctors-details .borders {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-left: 1px;
  margin-right: 1px;
}
.doctors-details .doctors-history {
  padding: 30px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.doctors-details .doctors-history h2 {
  font-size: 40px;
}
.doctors-details .doctors-history span {
  font-weight: 600;
  color: #ef8017;
  font-size: 20px;
  display: block;
  margin-bottom: 15px;
}
.doctors-details .doctors-history p {
  margin-bottom: 30px;
}
.doctors-details .left-title h3 {
  font-size: 17px;
}
.doctors-details .right-title ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}
.doctors-details .right-title ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.doctors-details .right-title ul li i {
  position: absolute;
  top: 3px;
  left: 0;
}

/*====================================================
OTHERS STYLE AREA
======================================================*/
/*
Preloader Area Style*/
.preloader {
  position: fixed;
  left: 0;
  width: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 9999999;
  transition: all ease 0.5s;
}
.preloader .loader {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 45%;
  transform: translateY(-45%);
  transition: all ease 0.5s;
}
.preloader .loader .loader-outter {
  position: absolute;
  border: 4px solid #ffffff;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.preloader .loader .loader-inner {
  position: absolute;
  border: 4px solid #ffffff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  left: calc(40% - 21px);
  top: calc(40% - 21px);
  border-right: 0;
  border-top-color: transparent;
  animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.preloader .loader .indicator {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%) scale(1.5);
}
.preloader .loader .indicator svg polyline {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.preloader .loader .indicator svg polyline#back {
  stroke: #ffffff;
}
.preloader .loader .indicator svg polyline#front {
  stroke: #ef8017;
  stroke-dasharray: 12, 36;
  stroke-dashoffset: 48;
  animation: dash 1s linear infinite;
}
.preloader::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 60%;
  z-index: -1;
  background: #ef8017;
  transition: all ease 0.5s;
}
.preloader::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 60%;
  z-index: -1;
  background: #ef8017;
  transition: all ease 0.5s;
  left: auto;
  right: 0;
}
.preloader.preloader-deactivate {
  visibility: hidden;
}
.preloader.preloader-deactivate::after {
  width: 0;
}
.preloader.preloader-deactivate::before {
  width: 0;
}
.preloader.preloader-deactivate .loader {
  opacity: 0;
  visibility: hidden;
}

@keyframes loader-outter {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-inner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
/*
Go Top Style*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 82%;
  right: -10%;
  background-color: #ef8017;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
}
.go-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  transition: 0.5s;
  font-size: 20px;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #ef8017;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.go-top:hover {
  color: #ffffff;
  background-color: #ef8017;
}
.go-top:hover::before {
  opacity: 1;
  visibility: visible;
}
.go-top:hover i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.go-top:focus {
  color: #ffffff;
}
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

/*
Video wave Style*/
.video-btn {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 0;
  color: #ef8017;
  position: relative;
  top: 3px;
  z-index: 1;
  background-color: rgba(238, 50, 50, 0.9);
  border-radius: 50%;
  transition: all ease 0.5s;
}
.video-btn i {
  font-size: 25px;
  color: #ffffff;
  position: absolute;
  top: 1px;
  left: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
}
.video-btn::after, .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 0;
  border-radius: 50%;
  transition: all ease 0.5s;
  animation: ripple 1.6s ease-out infinite;
  background-color: rgba(238, 50, 50, 0.9);
}
.video-btn:hover::before, .video-btn:hover::after {
  background-color: rgba(238, 50, 50, 0.9);
}

@keyframes ripple {
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
/*
Nice select Area Style*/
.nice-select .list {
  width: 100%;
  border-radius: 0;
}

.nice-select .option:hover {
  background-color: #ef8017;
  color: #ffffff;
}
.nice-select .option.selected.focus {
  color: #ef8017;
}
.nice-select .current {
  font-size: 16px;
}

/*
Page-navigation Area Style*/
.pagination-area {
  margin-top: 10px;
  text-align: center;
}
.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #222222;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 17px;
  background-color: #f5f6fa;
  border: 1px solid #d7d7d7;
}
.pagination-area .page-numbers:hover {
  color: #ffffff;
  border-color: #ef8017;
  background-color: #ef8017;
}
.pagination-area .page-numbers i {
  position: relative;
  font-size: 25px;
  top: 5px;
}
.pagination-area .page-numbers.current {
  color: #ffffff;
  border-color: #ef8017;
  background-color: #ef8017;
}

/*
product-view-one
=================================================*/
.product-view-one {
  padding-right: 0 !important;
}
.product-view-one.modal.show .modal-dialog {
  margin-top: 25px;
}
.product-view-one .product-view-one-image {
  background-color: #eeeeee;
  padding: 30px;
}
.product-view-one .modal-dialog {
  max-width: 900px;
  margin: 0 auto;
}
.product-view-one .modal-content {
  border: none !important;
  padding: 40px;
  border-radius: 0 !important;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.15);
}
.product-view-one .modal-content button.close {
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  opacity: 1;
  color: #222222;
  transition: 0.5s;
  line-height: 35px;
  padding: 0;
  margin: 0;
  font-size: 20px;
  width: 35px;
  text-shadow: unset;
  height: 35px;
}
.product-view-one .modal-content button.close:hover {
  background-color: #ff0000;
  color: #ffffff;
}
.product-view-one .modal-content .product-content h3 {
  font-size: 22px;
  margin-bottom: 14px;
}
.product-view-one .modal-content .product-content h3 a {
  color: #222222;
}
.product-view-one .modal-content .product-content h3 a:hover {
  color: #222222;
}
.product-view-one .modal-content .product-content .price {
  margin-bottom: 10px;
}
.product-view-one .modal-content .product-content .price span {
  display: inline-block;
  font-size: 18px;
  color: #222222;
}
.product-view-one .modal-content .product-content .price del {
  display: inline-block;
  font-size: 18px;
  color: #c1c1c1;
  margin-left: 5px;
}
.product-view-one .modal-content .product-content .product-review {
  margin-bottom: 10px;
}
.product-view-one .modal-content .product-content .product-review .rating {
  display: inline-block;
  padding-right: 5px;
}
.product-view-one .modal-content .product-content .product-review .rating i {
  color: #ef8017;
}
.product-view-one .modal-content .product-content .product-review .rating-count {
  display: inline-block;
  color: #222222;
  border-bottom: 1px solid #222222;
  line-height: initial;
}
.product-view-one .modal-content .product-content .product-info {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.product-view-one .modal-content .product-content .product-info li {
  font-size: 16px;
  color: #222222;
  margin-bottom: 10px;
}
.product-view-one .modal-content .product-content .product-info li:last-child {
  margin-bottom: 0;
}
.product-view-one .modal-content .product-content .product-info li span {
  color: #222222;
}
.product-view-one .modal-content .product-content .product-info li a {
  display: inline-block;
  font-weight: 500;
  color: #222222;
}
.product-view-one .modal-content .product-content .product-info li a:hover {
  color: #222222;
}
.product-view-one .modal-content .product-content .product-color-switch {
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-view-one .modal-content .product-content .product-color-switch h4 {
  font-size: 16px;
  color: #222222;
  margin-bottom: 6px;
}
.product-view-one .modal-content .product-content .product-color-switch ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -4px;
  margin-left: -4px;
  padding-left: 0;
}
.product-view-one .modal-content .product-content .product-color-switch ul li {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a {
  display: inline-block;
  position: relative;
  border: 1px solid transparent;
  width: 25px;
  height: 25px;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  margin: 2px;
  transition: 0.5s;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a:hover {
  border-color: #222222;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a:focus {
  border-color: #222222;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-white::before {
  background: blue;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-black::before {
  background: #222222;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-green::before {
  background: green;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-yellowgreen::before {
  background: yellowgreen;
}
.product-view-one .modal-content .product-content .product-color-switch ul li a.color-teal::before {
  background: teal;
}
.product-view-one .modal-content .product-content .product-color-switch ul li.active a {
  border-color: #222222;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #666666;
  width: 40px;
  height: 100%;
  line-height: 50px;
  transition: all ease 0.5s;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span:hover {
  color: #222222;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span.minus-btn {
  left: 0;
  transition: all ease 0.5s;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span.minus-btn:hover {
  background-color: #ef8017;
  color: #ffffff;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span.plus-btn {
  right: 0;
  transition: all ease 0.5s;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter span.plus-btn:hover {
  background-color: #ef8017;
  color: #ffffff;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter input {
  height: 48px;
  color: #222222;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}
.product-view-one .modal-content .product-content .product-add-to-cart .input-counter input::placeholder {
  color: #222222;
}
.product-view-one .modal-content .product-content .share-this-product h3 {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 10px;
}
.product-view-one .modal-content .product-content .share-this-product ul li {
  display: inline-block;
  margin-right: 3px;
}
.product-view-one .modal-content .product-content .share-this-product ul li a i {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  background-color: #eeeeee;
  border-radius: 4px;
  text-align: center;
  transition: all ease 0.5s;
}
.product-view-one .modal-content .product-content .share-this-product ul li a:hover i {
  background-color: #ef8017;
  color: #ffffff;
}
.product-view-one .owl-carousel .owl-item img {
  width: unset;
  margin: auto;
}
.product-view-one #big .item {
  padding: 30px;
  background-color: #ffffff;
  margin-bottom: 30px;
}
.product-view-one .owl-item.active.current {
  background-color: #ffffff;
  padding: 5px;
}
.product-view-one.fade {
  animation: zoomIn 1s linear;
}
.product-view-one .owl-next {
  position: absolute;
  top: 50%;
  right: 50px;
  margin: 0 !important;
  border-radius: 0 !important;
  transition: all ease 0.5s;
  opacity: 0;
  transform: translateY(-25px);
}
.product-view-one .owl-next i {
  background-color: #ef8017;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  transition: all ease 0.5s;
  border-radius: 4px;
  font-size: 15px;
}
.product-view-one .owl-prev {
  position: absolute;
  top: 50%;
  left: 50px;
  margin: 0 !important;
  border-radius: 0 !important;
  transition: all ease 0.5s;
  opacity: 0;
  transform: translateY(-25px);
}
.product-view-one .owl-prev i {
  background-color: #ef8017 !important;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  transition: all ease 0.5s;
  font-size: 15px;
  border-radius: 4px;
}
.product-view-one:hover .owl-prev {
  opacity: 1;
  left: 0;
}
.product-view-one:hover .owl-next {
  opacity: 1;
  right: 0;
}
.product-view-one .owl-theme .owl-nav {
  margin-top: 0 !important;
}
.product-view-one .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}

.shopping-cart-area .cart-table table tbody tr td {
  white-space: nowrap;
}
.shopping-cart-area .cart-table table tbody tr td:first-child {
  text-align: left;
  padding-left: 0;
}